:root {
  --primary-color: #EE05F2;
  --primary-dark: #2F1D73; 
  --primary-bg: #FDFAEB;
  --secondary-color: #F088F2;
  --secondary-dark: #6451A6;
  --overlay: (0,0,0, .7); 
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Playfair Display', serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  line-height: 1.2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.button {
  padding: 17px 28px;
  font-size: 1.2rem;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background: var(--primary-dark);
  border: 1px solid var(--primary-dark);
}

.line {
  display: block;
  width: 40px;
  height: 3px;
  margin: 5px auto;
  background-color: var(--primary-color);
}

.top-page {
  width: 100%;
  padding-top:90px;
  margin:auto;
}

footer {
  text-align: center;
  padding: 25px;
  border-top: solid 0.25px #eee;
  color: #333;
}

.footer {
  background-color: #fff;
}


@media screen and (max-width:769px){
  .top-page {
      width: 100%;
  }

  .sbx-cursor {
    display: none;
  }
}