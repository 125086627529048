/* Intro */

#intro-section {
    width: 75%;
    margin: auto;
    padding: 60px 0px 40px 0px;
    text-align: center;
    border-bottom: solid 0.25px #A0A0A0;
}

#intro-section h3 {
    color: var(--secondary-color);
}

#intro-section h1 {
    color: var(--primary-dark);
}

@media screen and (max-width:769px){
    #hero-section {
        display:block;
        margin: auto;
    }

}