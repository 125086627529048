.about {
  display: block;
  width: 650px;
  background-color: #fff;
  margin: auto;
  padding: 35px;
  border: solid 0.25px #a0a0a0;
}

.about-text {
  width: 95%;
  margin: auto;
}

.about h3 {
  color: var(--secondary-color);
  text-align: center;
  padding-bottom: 25px;
}

.image {
  display: flex;
  align-items: center;
  margin-top: -45px;
}

.image img {
  padding: 10px;
}

.about img {
  max-width: 100%;
  height: auto;
}

.image button {
  background-color: rgb(73, 73, 204);
  padding: 8px;
  border-style: none;
}

.image button a {
  text-decoration: none;
  color: #fff;
}

.image button a:hover {
  color: orange;
}

@media screen and (max-width: 769px) {
  .about {
    width: 400px;
  }

  .image {
    display: block;
  }
  .image button {
    display: block;
    margin: auto;
  }
}
