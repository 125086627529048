.social-icons {
    width: 15%;
    position: absolute;
}

.icons  {
    list-style-type: none;
    text-align: left;
    padding:20px;
    margin-left:20px;
    margin-top:50px;
}

.icons a {
    color: var(--primary-dark);
    font-size: 1.3rem;
}

.icons a:hover {
    color: var(--secondary-color);
}