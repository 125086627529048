.header {
    position: fixed;
    height: 90px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    background-color: #fff; 
}

.header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
}

.header .nav-menu a {
    color: var(--secondary-color)
}

.header .navbar img {
    width: 250px;
    height: auto;
}

.navbar a {
    text-decoration: none;
}

.logo {
    font-size: 2rem;
    font-weight: 800;
    color: #15295F;
}

.header .nav-menu {
    display: flex;
}

.header .nav-item {
    padding: 1rem;
    font-weight: 500;
}

.header .nav-item a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--primary-color);
}

.hamburger {
    display: none;
}

@media screen and (max-width:940px) {
    .header {
        max-width: 100%;
        border-bottom: solid 1px #eee; 
    }

    .header .navbar {
        max-width: 100%;
    }

    .hamburger {
        display: block;
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 5rem;
        flex-direction: column;
        background-color: rgba(8, 7, 30, 0.944);
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
    }

    .nav-menu.active {
        left: 0;
        color: var(--secondary-color)
    }

    .nav-item {
        margin: 1.5rem 0;
        font-size: 1.5rem;
    }

}