.container {
  text-align: center;
  padding: 50px;
  width: 960px;
}

.container h2 {
  padding: 25px;
  color: var(--secondary-color);
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card {
  background-color: #fff;
  border: solid 1px plum;
  border-radius: 10px;
  /* box-shadow: 3px 3px 3px hsla(298, 77%, 49%, 0.177); */
  padding: 20px;
  margin: 10px;
  text-align: center;
  display: inline-block;
}

.card img {
  border: solid 1px #eee;
  /* box-shadow: 3px 3px 3px hsla(298, 77%, 49%, 0.177); */
}

.card-title {
  margin: 0;
}

.card {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.card a {
  text-decoration: none;
  color: var(--primary-color);
}

.card a:hover {
  color: var(--secondary-color);
}

@media screen and (max-width: 769px) {
  .card-container {
    display: inline-block;
  }
}
