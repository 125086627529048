.line {
  border: solid #eee 0.25px;
}

.skills {
  text-align: center;
  width: 100%;
  margin: auto;
  padding: 25px;
  background-color: #fff;
}

.skills h2 {
  color: var(--primary-dark);
  font-size: 1.5rem;
  font-weight: 600;
  padding-bottom: 60px;
}
.skills-group {
  display: flex;
  justify-content: center;
  margin: 30px;
  padding: 20px;
  border-bottom: solid 1px #eee;
}

/* .skills-group2 {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-top: 20px;
} */

.skills p {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 0.8rem;
}

.skills-card h4 {
  color: var(--primary-dark);
}

.skills-card {
  padding-right: 20px;
}

@media screen and (max-width: 769px) {
  /* .skills-group {
    display: block;
  } */
}
